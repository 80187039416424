<template>
  <div class="admin-feed-block AdminSubscriptionsReport " :class="{ loading: loading }">
    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <Spacer num="1" />
      <div class="reportsTotals tableXContent" dcard dpadding dradius>
        <table class="table" width="100%">
          <tr>
            <th>{{ $locale["total_pays"] }}</th>
            <th>{{ $locale["total_credits"] }}</th>
            <th>{{ $locale["total_subscriptions"] }}</th>
            <th>{{ $locale["total_mount"] }}</th>
          </tr>
          <tr>
            <td tcenter>
              <span skeleton strong>{{ totals.totalItems }}</span>
            </td>
            <td tcenter>
              <span skeleton strong>$ {{ Go.number_format(totals.totalCredits, 2) }}</span>
            </td>
            <td tcenter>
              <span skeleton strong>$ {{ Go.number_format(totals.totalSubscriptions, 2) }}</span>
            </td>
            <td tcenter>
              <span skeleton strong>$ {{ Go.number_format(totals.totalMount, 2) }}</span>
            </td>
          </tr>
        </table>
      </div>
      <Spacer num="1" />
      <div class="ReportInfo ReportPayments tableXContent">
        <div>
          <table class="table reportTable" width="100%">
            <tr>
              <th tleft>{{ $locale["date"] }}</th>
              <th tleft>{{ $locale["user"] }}</th>
              <th tleft>{{ $locale["type"] }}</th>
              <th tleft>{{ $locale["tranid"] }}</th>
              <th tright>{{ $locale["mount"] }}</th>
            </tr>
            <tr v-for="(payment, index) in items" :key="index">
              <td>
                <span block skeleton>{{ Go.date_locale(payment.date) }}</span>
              </td>
              <td>
                <span block skeleton>@{{ payment.user.user }}</span>
              </td>
              <td>
                <span block skeleton>{{ $locale[payment.type] }}</span>
              </td>
              <td>
                <span block skeleton>{{ payment.pay.tranid }}</span>
              </td>
              <td tright>
                <span block skeleton>{{ payment.price }}</span>
              </td>
            </tr>
          </table>
        </div>
        <LoadMore :morepage="statistics.hasMore" @more="getStatistics({ page: statistics.page + 1 })" />
      </div>
    </div>
  </div>
</template>

<script>
import AdminStatistics from "./AdminStatistics";
export default {
  mixins: [AdminStatistics],
  components: {
    AdminStatisticsFilter: () => import("./AdminStatisticsFilter.vue"),
    AdminStatisticsUsersCreatorsCard: () => import("./AdminStatisticsUsersCreatorsCard.vue"),
  },
  data: function() {
    return {
      loading: false,
      store: Go.store("sales_by_creator"),
      items: [],
      statistics: { totalItems: 0, page: 1 },
      totals: {},
    };
  },
  methods: {
    getStatistics: async function({ page = 1 } = {}) {
      this.isLoading(true);
      await Go.sleep(250);

      try {
        this.query = Go.objectToQuery(this.$route.query);
        this.req = await this.api.get(`admin/getPayments?${this.query}&page=${page}`);
        this.reqSuccess(this.req.data);
      } catch (error) {
        this.reqError(error);
      }

      this.isLoading(false);
    },
    reqSuccess: function({ statistics, items, totals }) {
      if (statistics && Number(statistics.page) === 1) {
        this.store.set("statistics", statistics);
        this.store.set("items", items);
        this.statistics = statistics;
        this.$store.state.admin.salesByCreatorList.statistics = statistics;
        this.items = items;
        this.totals = totals;
        return;
      }

      this.statistics = statistics;
      this.items = [...this.items, ...items];
      this.store.set("statistics", statistics);
      this.store.set("items", this.items);
    },
    reqError: function(data = {}) {
      this.showMessage(data);
    },
    UserStatisticsResume: function(creator) {
      this.view({
        user: creator,
        title: `@${creator.user}`,
        component: () => import("./UserStatisticsResume.vue"),
        class: "bottom menu",
        closeOutside: true,
        animation: "bottomIn",
        statistics: this.statistics,
      });
    },
  },
  mounted: function() {
    this.items = this.store.get("items") || [];
    this.statistics = this.store.get("statistics") || { totalItems: 0 };
    this.getStatistics({ page: 1 });
  },
};
</script>
